export default function getDateFormatShort(): string {
  switch (window.StoryChief.settings.dateFormat) {
    case 'm/d/Y h:i A':
    case 'm/d/Y H:i':
      return 'M/d';
    case 'd/m/Y h:i A':
    case 'd/m/Y H:i':
    default:
      return 'd/M';
  }
}
