export default function getDateFormatHour(): string {
  switch (window.StoryChief.settings.dateFormat) {
    case 'm/d/Y h:i A':
    case 'd/m/Y h:i A':
      return 'h:mma';
    case 'm/d/Y H:i':
    case 'd/m/Y H:i':
    default:
      return 'HH:mm';
  }
}
