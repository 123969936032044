import getDateFormatHour from '@/date/getDateFormatHour';

export default function getDateFormatLong(): string {
  switch (window.StoryChief.settings.dateFormat) {
    case 'm/d/Y h:i A':
    case 'm/d/Y H:i':
      return `M/d/yy - ${getDateFormatHour()}`;
    case 'd/m/Y h:i A':
    case 'd/m/Y H:i':
    default:
      return `d/M/yy - ${getDateFormatHour()}`;
  }
}
