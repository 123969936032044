export const DATE_TIME_INTERVAL = 5;

export const DATE_FORMAT_TYPE = {
  long: 'long',
  monthLong: 'monthLong',
  short: 'short',
  hour: 'hour',
  full: 'full',
  default: 'default',
} as const;

export type DateFormatType = keyof typeof DATE_FORMAT_TYPE;
