import getDateFormatLong from '@/date/getDateFormatLong';
import getDateFormatShort from '@/date/getDateFormatShort';
import getDateFormatHour from '@/date/getDateFormatHour';
import getDateFormatDefault from '@/date/getDateFormatDefault';
import getDateFormatMonthLong from '@/date/getDateFormatMonthLong';
import getDateFormatFull from '@/date/getDateFormatFull';
import { DATE_FORMAT_TYPE } from './constants';
import type { DateFormatType } from './constants';

export default function getDateFormat(type: DateFormatType): string {
  switch (DATE_FORMAT_TYPE[type]) {
    case DATE_FORMAT_TYPE.long:
      return getDateFormatLong();
    case DATE_FORMAT_TYPE.monthLong:
      return getDateFormatMonthLong();
    case DATE_FORMAT_TYPE.short:
      return getDateFormatShort();
    case DATE_FORMAT_TYPE.hour:
      return getDateFormatHour();
    case DATE_FORMAT_TYPE.full:
      return getDateFormatFull();
    default:
      if (type === DATE_FORMAT_TYPE.default) {
        return getDateFormatDefault();
      }

      // Custom format
      return type;
  }
}
