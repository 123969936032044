export default function getDateFormatDefault(): string {
  switch (window.StoryChief.settings.dateFormat) {
    case 'm/d/Y h:i A':
    case 'm/d/Y H:i':
      return 'M/d/yyyy';
    case 'd/m/Y h:i A':
    case 'd/m/Y H:i':
    default:
      return 'd/M/yyyy';
  }
}
